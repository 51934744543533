import React from "react"
import { StaticImage } from "gatsby-plugin-image"

  
  export default function Section2() {
    return (
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Informez-vous</h2>
  
            <ul
              className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
            >

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Les contextes"
                        src="../../images/contextes.jpg" 
                      />

                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">Les contextes</h3>
                          <p className="text-[#004C8E]"><a href="contextes-maisons-fissurees/">Dans quels contexte peut-on être confronté à une maison fissurée ?</a></p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">Dans le cadre d’un achat ou d’une vente, suite à une succession, pendant des travaux ou en tant que propriétaire, il existe de multiples situations dans lesquelles on peut être confronté à la fissuration d’une maison. Découvrez nos conseils en fonction de votre situation.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Les types de maison fissurées"
                        src="../../images/types-de-maisons-fissurees.jpg" 
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">
                          Les types de maison fissurées
                          </h3>
                          <p className="text-[#004C8E]">
                          <a href="/types-maisons-fissurees/">Quels sont les types de maisons sujettes aux fissures ?</a>
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">
                          Qu’elle soit à étage ou de plain-pied, mitoyenne ou isolée, en pisé ou en brique, ancienne ou récente, en fonction du type de maison, les fissures ne doivent pas être considérées de la même façon. pour chaque maison fissurée son type a une importance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Analyser une maison fissurée"
                        src="../../images/analyser-maison-fissuree.jpg" 
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">
                          Analyser une maison fissurée
                          </h3>
                          <p className="text-[#004C8E]">
                          <a href="/analyser-fissures-maison/fissure-maison-danger/">Quelles sont les fissures dangereuses ?</a>
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">
                          Confronté à une maison qui se fissure, il n’est pas toujours facile de prendre du recul par rapport à la situation pour prendre les bonnes décisions. Découvrez les clés d’une analyse réussie.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Réparer une maison fissurée"
                        src="../../images/reparer-une-maison-fissuree.jpg" 
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">
                          Réparer une maison fissurée
                          </h3>
                          <p className="text-[#004C8E]">
                          <a href="/reparer-fissures-maison/">Comment réparer une maison qui se fissure ?</a>
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">
                          Vous souhaitez en savoir plus sur les solutions et modes de réparation des fissures. Nous vous donnons toutes les clés pour bien comprendre et faire le bon choix.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Les recours en cas de fissure"
                        src="../../images/recours-maison-fissuree.jpg" 
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">
                          Les recours en cas de fissure
                          </h3>
                          <p className="text-[#004C8E]">
                          <a href="/recours-maison-fissure/">Quels sont les recours ?</a>
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">
                          En cas de maison fissurée et dans certaines conditions, il est possible d’engager un recours à l’encontre d’un tiers (voisin, assurance, vendeur). En fonction de votre situation, nous vous donnons les informations utiles pour engager un recours efficace.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Le régime de catastrophe naturelle sécheresse"
                        src="../../images/regime-catastrophe-naturelle.jpg" 
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">
                          Le régime de catastrophe naturelle sécheresse
                          </h3>
                          <p className="text-[#004C8E]">
                          <a href="/secheresse-fissure-maison/">Comment déclarer un sinistre sécheresse ?</a>
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">
                          Lorsque la sécheresse est à l’origine de l’apparition de fissures sur une maison, il est possible de déclarer un sinistre à son assurance. Suivez nos conseils sur ce régime bien particulier pour ne pas commettre d’erreur.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-2">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg" 
                        alt="Trouvez un expert fissure"
                        src="../../images/trouver-un-expert-fissure.jpg" 
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-xl font-bold">
                          Trouvez un expert fissure
                          </h3>
                          <p className="text-[#004C8E]">
                          <a href="/contact">Qui contacter en cas de fissure ?</a>
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500 font-light">
                          Quelque soit votre localisation et votre situation, trouvez l’expert qui pourra vous répondre et vous accompagner.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

            </ul>
          </div>
        </div>
      </div>
    )
  }
  