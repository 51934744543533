import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/headers/header"
import Section1 from "../components/index/section1"
import Section2 from "../components/index/section2"
import Footer from "../components/footers/footer"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Maison Fissure</title>
        <meta name="description" content="Maison Fissure" />
      </Helmet>

      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <Section1 />
        <Section2 />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}